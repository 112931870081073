export const textData = {
    KSK: {
        en: {
            productTitle: {
                title: 'THANK YOU FOR CHOOSING US TO PROTECT YOUR CHILDREN',
                description: 'Kaspersky Safe Kids helps you to protect your children - so they can enjoy the digital world, on their computers & mobiles.',
                serial: 'Your activation code : ',
            },
            productTableHelp: {
                title: 'Get Started',
                tabs: [
                    {
                        one: { title: 'Open the Web browser on your child’s PC and enter this link: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Click the ‘Download for PC’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp12' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the Web browser on your child’s Mac andenter this link: <a href="https://kas.pr/kids" class="anchorLink" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Click the ‘Download for Mac’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp22' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids"> My Kaspersky </a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the App Store on your child’s device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp31' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open Google Play Store on your child’s Android device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp41' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    }
                ],
            },
            productLinks: {
                title: 'Download Kaspersky Safe Kids',
                buttonText: 'Download'
            },
        },
        de: {
            productTitle: {
                title: 'DANKE, DASS SIE UNS DEN SCHUTZ IHRER KINDER ANVERTRAUEN',
                description: 'Kaspersky Safe Kids schützt Ihre Kinder, damit sie die digitale Welt auf Computern und Mobilgeräten genießen können.',
                serial: 'Ihr Aktivierungscode : ',
            },
            productTableHelp: {
                title: 'ERSTE SCHRITTE',
                tabs: [
                    {
                        one: { title: 'Geben Sie im Browser auf dem PC Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Klicken Sie auf „Für PC herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp12' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Geben Sie im Browser auf dem Mac Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Klicken Sie auf „Für Mac herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp22' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den App Store auf dem Gerät Ihres Kindes und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp31' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den Google Play Store auf dem Android-Gerät Ihres Kindes, und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp41' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    }
                ],
            },
            productLinks: {
                title: 'LADEN SIE KASPERSKY SAFE KIDS HERUNTER',
                buttonText: 'FÜR PC HERUNTERLADEN'
            },
        }
    },
    KPM: {
        en: {
            productTitle: {
                title: 'THANK YOU FOR CHOOSING US TO PROTECT YOUR CHILDREN',
                description: 'Kaspersky Password Manager securely stores every password. You only need to remember one master password… to access all your individual passwords.<br /><ul><li>&#10003; Stores passwords, card numbers &amp; more</li><li>&#10003; Access via PC, Mac, mobile <a target="_blank" href="https://my.kaspersky.com/MyPasswords">My-Kaspersky</a></li><li>&#10003; Simplifies sign-in for websites &amp; accounts</li></ul>',
            },
            htmlBlock: [
                {type: 'head', html: 'How to activate Kaspersky Password Manager'}, {type: 'brake'},
                {type: 'html', html: 'To start using Kaspersky Password Manager, connect it to your account on <a target="_blank" href="https://my.kaspersky.com/">My&nbsp;Kaspersky</a>'}, {type: 'brake'},
                {type: 'html', html: '&#10003; If you already have an account, enter your login and password and click <b>Sign&nbsp;In</b>'}, {type: 'brake'},
                {type: 'html', html: '&#10003; If you do not have an account, click <b>Create Kaspersky Account right now</b>'}, {type: 'brake'},

                {type: 'head', html: 'Add the activation code to your account on My Kaspersky and synchronize the product with your account:'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Sign in to your account at <a target="_blank" href="https://my.kaspersky.com/">My&nbsp;Kaspersky</a>'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Click <b>Licenses</b>.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Enter your license code and click <b>Add</b>.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Unlock Kaspersky Password Manager.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; In the lower part of the Kaspersky Password Manager window, click the <b>Synchronization</b> link, then click <b>Synchronize</b>.'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/consumer', title: 'Knowledge Base'},
            ],
            productLinks: {
                buttonText: 'DOWNLOAD NOW',
                distrName: ['SETUP', 'Protection for PC', 'Protection for Mac', 'Safe surfing – for iPhone iPad', 'Protection for Android™', 'SETUP']
            },
        },
        de: {
            productTitle: {
                title: 'DANKE, DASS SIE UNS DEN SCHUTZ IHRER PASSWÖRTER ANVERTRAUEN',
                description: 'Der Kaspersky Password Manager sorgt für die sichere Speicherung jedes einzelnen Passworts. Sie brauchen sich nur noch ein Master-Passwort zu merken, mit dem Sie Zugriff auf all Ihre individuellen Passwörter haben.<br /><ul><li>&#10003; Speicherung von Passwörtern, Kreditkartennummern und mehr</li><li>&#10003; Zugriff über PC, Mac, Mobiltelefone und <a target="_blank" href="https://my.kaspersky.com/MyPasswords">My-Kaspersky"</a></li><li>&#10003; Vereinfachte Anmeldung für Webseiten und Konten</li></ul>',
            },
            htmlBlock: [
                {type: 'head', html: 'Aktivieren von Kaspersky Password Manager'}, {type: 'brake'},
                {type: 'html', html: 'Um Kaspersky Password Manager zu nutzen, müssen Sie das Programm an Ihr Benutzerkonto auf dem Portal <a target="_blank" href="https://my.kaspersky.com/de">My&nbsp;Kaspersky</a> knüpfen:'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Wenn Sie ein Benutzerkonto auf dem Portal My Kaspersky haben, geben Sie den Benutzernamen und das Kennwort ein und klicken Sie auf Anmelden.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Wenn Sie noch kein Benutzerkonto haben, dann klicken Sie auf den Link Jetzt ein Benutzerkonto erstellen.'}, {type: 'brake'},

                {type: 'head', html: 'Fügen Sie den Aktivierungscode zu Ihrem Benutzerkonto bei My Kaspersky hinzu und führen Sie die Synchronisierung aus:'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Melden Sie sich unter Ihrem Benutzerkonto auf dem Portal <a target="_blank" href="https://my.kaspersky.com/de">My&nbsp;Kaspersky</a>.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Wechseln Sie zum Abschnitt <b>Lizenzen</b>.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Geben Sie den Aktivierungscode ein und klicken Sie auf <b>Hinzufügen</b>.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Entsperren Sie Kaspersky Password Manager.'}, {type: 'brake'},
                {type: 'html', html: '&#10003; Klicken Sie unten im Programmfenster auf den Link <b>Synchronisierung</b> und dann auf <b>Synchronisieren</b>.'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/consumer', title: 'Wissensdatenbank'},
            ],
            productLinks: {
                buttonText: 'JETZT HERUNTERLADEN',
                distrName: ['SETUP', 'PC-Schutz', 'Mac-Schutz', 'iOS-Schutz', 'Android™-Schutz', 'SETUP'],
                distrTitle: ['', '', 'Laden Sie genau den passenden Schutz für die OS X-Version Ihres Mac herunter. Wählen Sie Ihre Version:', 'Laden Sie genau den passenden Schutz für die iOS Version Ihres iPhone herunter. Wählen Sie Ihre Version:', '', ''],
            },
        }
    },
    KISA: {
        en: {
            productTitle: {
                title: 'THANK YOU FOR CHOOSING US TO PROTECT YOUR DIGITAL LIFE',
                description: 'Kaspersky Internet Security for Android delivers protection for Android. To start using the product, please choose below what you would like to protect.'
            },
            htmlBlock: [
                {type: 'head', html: 'Additional Resources'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/10186', title: 'How to activate Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/mobile/kisandroid', title: 'Knowledge Base: Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'head', html: 'My Kaspersky'}, {type: 'brake'},


                {type: 'html', html: 'My Kaspersky opens up more opportunities to secure everything that matters for you.'}, {type: 'brake'},
                {type: 'html', html: 'At any time and from anywhere, you can sign in to the portal to check the security status, change settings, and easily remove security threats.'}, {type: 'brake'},
                {type: 'link', href: 'https://my.kaspersky.com/', title: 'Sign up now'},

            ],
            productTableHelp: {
                title: 'Get Started',
                tabs: [
                    {
                        one: { title: 'Open the Web browser on your child’s PC and enter this link: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Click the ‘Download for PC’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp12' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the Web browser on your child’s Mac andenter this link: <a href="https://kas.pr/kids" class="anchorLink" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Click the ‘Download for Mac’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp22' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids"> My Kaspersky </a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the App Store on your child’s device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp31' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open Google Play Store on your child’s Android device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp41' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    }
                ],
            },
            productLinks: {
                buttonText: 'DOWNLOAD NOW',
                distrName: ['SETUP', 'Protection for PC', 'Protection for Mac', 'Safe surfing – for iPhone iPad', 'Protection for Android™', 'SETUP']
            },
        },
        de: {
            productTitle: {
                title: 'DANKE, DASS SIE UNS DEN SCHUTZ IHRES DIGITALEN LEBENS ANVERTRAUEN',
                description: 'Kaspersky Internet Security for Android bietet Schutz für Android-Geräte. Um das Produkt verwenden zu können, wählen Sie aus, welche Geräte Sie schützen möchten.'
            },
            htmlBlock: [
                {type: 'head', html: 'ZUSÄTZLICHE RESSOURCEN'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/10186', title: 'Aktivieren von Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/mobile/kisandroid', title: 'Wissendatenbank: Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'head', html: 'My Kaspersky'}, {type: 'brake'},

                {type: 'html', html: 'Mehr Funktionen zum Schutz Ihrer Online-Aktivitäten'}, {type: 'brake'},
                {type: 'html', html: 'My Kaspersky eröffnet neue Möglichkeiten, um alles zu schützen, was Ihnen wichtig ist.'}, {type: 'brake'},
                {type: 'html', html: 'Sie können sich jederzeit und von überall beim Portal anmelden, um den Sicherheitsstatus zu überprüfen, die Schutzeinstellungen zu ändern und Sicherheitsbedrohungen zu neutralisieren.'}, {type: 'brake'},
                {type: 'link', href: 'https://my.kaspersky.com/de', title: 'Registrieren Sie sich jetzt'},

            ],
            productTableHelp: {
                title: 'ERSTE SCHRITTE',
                tabs: [
                    {
                        one: { title: 'Geben Sie im Browser auf dem PC Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Klicken Sie auf „Für PC herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp12' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Geben Sie im Browser auf dem Mac Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Klicken Sie auf „Für Mac herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp22' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den App Store auf dem Gerät Ihres Kindes und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp31' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den Google Play Store auf dem Android-Gerät Ihres Kindes, und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp41' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    }
                ],
            },
            productLinks: {
                buttonText: 'JETZT HERUNTERLADEN',
                distrName: ['SETUP', 'PC-Schutz', 'Mac-Schutz', 'iOS-Schutz', 'Android™-Schutz', 'SETUP'],
                distrTitle: ['', '', 'Laden Sie genau den passenden Schutz für die OS X-Version Ihres Mac herunter. Wählen Sie Ihre Version:', 'Laden Sie genau den passenden Schutz für die iOS Version Ihres iPhone herunter. Wählen Sie Ihre Version:', '', ''],
            },
        }
    },
    KIS: {
        en: {
            productTitle: {
                title: 'THANK YOU FOR CHOOSING US TO PROTECT YOUR DIGITAL LIFE',
                description: 'Kaspersky Internet Security delivers protection for PC, Mac and Android. To start using the product, please choose below what you would like to protect.'
            },
            htmlBlock: [
                {type: 'head', html: 'Additional Resources'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/13615', title: 'How to activate Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/kis2018', title: 'Knowledge Base: Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'head', html: 'My Kaspersky'}, {type: 'brake'},


                {type: 'html', html: 'My Kaspersky opens up more opportunities to secure everything that matters for you.'}, {type: 'brake'},
                {type: 'html', html: 'At any time and from anywhere, you can sign in to the portal to check the security status, change settings, and easily remove security threats.'}, {type: 'brake'},
                {type: 'link', href: 'https://my.kaspersky.com/', title: 'Sign up now'},

            ],
            productTableHelp: {
                title: 'Get Started',
                tabs: [
                    {
                        one: { title: 'Open the Web browser on your child’s PC and enter this link: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Click the ‘Download for PC’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp12' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the Web browser on your child’s Mac andenter this link: <a href="https://kas.pr/kids" class="anchorLink" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Click the ‘Download for Mac’ button and install Kaspersky Safe Kids on your child’s computer – then follow the easy instructions on the screen.', icon: 'iconHelp22' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids"> My Kaspersky </a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open the App Store on your child’s device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp31' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    },
                    {
                        one: { title: 'Open Google Play Store on your child’s Android device and – using the Search function – find Kaspersky Safe Kids.' },
                        two: { title: 'Start the download and follow the easy instructions on the screen.', icon: 'iconHelp41' },
                        tree: { title: 'To manage the protection rules, please log in to the <a target="_blank" href="https://my.kaspersky.com/MyKids">My Kaspersky</a> portal – using the My Kaspersky login &amp; password that you used during installation.' },
                        four: { title: 'You can also install Kaspersky Safe Kids on your iPhone, iPad or Android device – so that you can remotely manage the protection rules that you apply and also get notifications about suspicious activities.' }
                    }
                ],
            },
            productLinks: {
                buttonText: 'DOWNLOAD NOW',
                distrName: ['SETUP', 'Protection for PC', 'Protection for Mac', 'Safe surfing – for iPhone iPad', 'Protection for Android™', 'SETUP']
            },
        },
        de: {
            productTitle: {
                title: 'DANKE, DASS SIE UNS DEN SCHUTZ IHRES DIGITALEN LEBENS ANVERTRAUEN',
                description: 'Kaspersky Internet Security bietet Schutz für PCs, Macs und Android-Geräte. Um das Produkt verwenden zu können, wählen Sie aus, welche Geräte Sie schützen möchten.'
            },
            htmlBlock: [
                {type: 'head', html: 'ZUSÄTZLICHE RESSOURCEN'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/13615', title: 'Aktivieren von Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/kis2018', title: 'Wissendatenbank: Kaspersky Internet Security'}, {type: 'brake'},
                {type: 'head', html: 'My Kaspersky'}, {type: 'brake'},

                {type: 'html', html: 'Mehr Funktionen zum Schutz Ihrer Online-Aktivitäten'}, {type: 'brake'},
                {type: 'html', html: 'My Kaspersky eröffnet neue Möglichkeiten, um alles zu schützen, was Ihnen wichtig ist.'}, {type: 'brake'},
                {type: 'html', html: 'Sie können sich jederzeit und von überall beim Portal anmelden, um den Sicherheitsstatus zu überprüfen, die Schutzeinstellungen zu ändern und Sicherheitsbedrohungen zu neutralisieren.'}, {type: 'brake'},
                {type: 'link', href: 'https://my.kaspersky.com/de', title: 'Registrieren Sie sich jetzt'},

            ],
            productTableHelp: {
                title: 'ERSTE SCHRITTE',
                tabs: [
                    {
                        one: { title: 'Geben Sie im Browser auf dem PC Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp11' },
                        two: { title: 'Klicken Sie auf „Für PC herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp12' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Geben Sie im Browser auf dem Mac Ihres Kindes Folgendes ein: <a href="https://kas.pr/kids" target="_blank">kas.pr/kids</a>', icon: 'iconHelp21' },
                        two: { title: 'Klicken Sie auf „Für Mac herunterladen“, und installieren Sie Kaspersky Safe Kids auf dem PC Ihres Kindes, indem Sie den Bildschirmanweisungen folgen.', icon: 'iconHelp22' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den App Store auf dem Gerät Ihres Kindes und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp31' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    },
                    {
                        one: { title: 'Öffnen Sie den Google Play Store auf dem Android-Gerät Ihres Kindes, und suchen Sie nach „Kaspersky Safe Kids“.' },
                        two: { title: 'Starten Sie den Download und folgen Sie den Anweisungen des Installationsassistenten.', icon: 'iconHelp41' },
                        tree: { title: 'Um die Regeln zu verwalten, melden Sie sich mit den Anmeldedaten, die Sie bereits bei der Installation verwendet haben, beim <a target="_blank" href="https://my.kaspersky.com/MyKids">My-Kaspersky-Portal</a> an.' },
                        four: { title: 'Sie können Kaspersky Safe Kids auf Ihrem iPhone, iPad oder Android-Gerät installieren, um die Schutzregeln per Fernzugriff zu verwalten und Benachrichtigungen zu verdächtigem Verhalten zu erhalten.' }
                    }
                ],
            },
            productLinks: {
                buttonText: 'JETZT HERUNTERLADEN',
                distrName: ['SETUP', 'PC-Schutz', 'Mac-Schutz', 'iOS-Schutz', 'Android™-Schutz', 'SETUP'],
                distrTitle: ['', '', 'Laden Sie genau den passenden Schutz für die OS X-Version Ihres Mac herunter. Wählen Sie Ihre Version:', 'Laden Sie genau den passenden Schutz für die iOS Version Ihres iPhone herunter. Wählen Sie Ihre Version:', '', ''],
            },
        }
    },
    KTS: {
        de: {
            productTitle: {
                title: 'VIELEN DANK, DASS SIE UNS DEN SCHUTZ IHRER FAMILIE ANVERTRAUEN',
                description: 'Nun können Sie den ultimativen Schutz für das digitale Leben Ihrer Familie herunterladen. Klicken Sie einfach auf die entsprechende Schaltfläche unten.',
            },
            htmlBlock: [
                {type: 'head', html: 'ZUSÄTZLICHE RESSOURCEN'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/14349', title: 'Aktivieren von Kaspersky Total Security'}, {type: 'brake'},
                {type: 'link', href: 'https://support.kaspersky.com/de/kts19', title: 'Wissensdatenbank: Kaspersky Total Security'}, {type: 'brake'},
                {type: 'head', html: 'My Kaspersky'}, {type: 'brake'},

                {type: 'html', html: 'Mehr Funktionen zum Schutz Ihrer Online-Aktivitäten'}, {type: 'brake'},
                {type: 'html', html: 'My Kaspersky eröffnet neue Möglichkeiten, um alles zu schützen, was Ihnen wichtig ist.'}, {type: 'brake'},
                {type: 'html', html: 'Sie können sich jederzeit und von überall beim Portal anmelden, um den Sicherheitsstatus zu überprüfen, die Schutzeinstellungen zu ändern und Sicherheitsbedrohungen zu neutralisieren.'}, {type: 'brake'},
                {type: 'link', href: 'https://my.kaspersky.com/de', title: 'Registrieren Sie sich jetzt'},
            ],
            productLinks: {
                buttonText: 'JETZT HERUNTERLADEN',
                distrName: ['PC-Schutz', 'PC-Schutz', 'Mac-Schutz', 'iOS-Schutz', 'Android™-Schutz', 'PC-Schutz'],
                distrTitle: ['', '', 'Version 2019 für macOS Sierra (10.12), High Sierra (10.13), Mojave (10.14)', 'Version 2019 für macOS Sierra (10.12), High Sierra (10.13), Mojave (10.14)', '', ''],
            },
        }
    },
    pageAll: {
        osStaticData: {
            MACSTATIC:      {icon: 'MainPage__product-icon-mac',        title: 'Mac-Schutz',                                        linkText: 'JETZT HERUNTERLADEN', linkType: '', description: 'Version 2018 für OS X El Capitan (10.11)', link: "https://products.s.kaspersky-labs.com/homeuser/kismac18/18.0.2.60/multilanguage-INT-20180517_134818/kaspersky%20internet%20security.dmg",},
            SAFEKIDSSTATIC: {icon: 'MainPage__product-icon-multi',      title: 'Schutz Ihrer Kinder',                               linkText: 'HERUNTERLADEN', linkType: '', link: "https://www.kaspersky.de/safe-kids",},
            PCPASSSTATIC:   {icon: 'MainPage__product-icon-windows',    title: 'Schutz für Passwörter auf Ihrem PC',                linkText: 'HERUNTERLADEN', linkType: '', link: "https://products.s.kaspersky-labs.com/homeuser/kpmwin9.0/9.0.1.447/multilanguage-0.3.0/a114a388/kpasswordmanager9.0.1.447da_de_en_es_es_fi_fr_it_ko_nb_nl_pl_pt_pt_ru_sv_tr_zh-Hans_zh-Hant_15320.exe",},
            MACPASSSTATIC:  {icon: 'MainPage__product-icon-mac',        title: 'Schutz für Passwörter auf Ihrem Mac',               linkText: 'HERUNTERLADEN', linkType: '', link: "https://itunes.apple.com/app/id1140461273",},
            ANDPASSSTATIC:  {icon: 'MainPage__product-icon-android',    title: 'Schutz für Passwörter auf Ihrem Android-Gerät',     linkText: '<div class="MainPage__product-icon-googleplay"></div>', linkType: '', link: "http://app.appsflyer.com/com.kaspersky.passwordmanager?pid=de_klsite&c=dp_button",}
        },
        os: ['DISTR', 'WIN', 'MAC', 'IOS', 'AND', 'WIN'],
        osData: [{headIcon: 'MainPage__product-icon-windows'}, {headIcon: 'MainPage__product-icon-windows'}, {headIcon: 'MainPage__product-icon-mac'}, {headIcon: 'MainPage__product-icon-ios'}, {headIcon: 'MainPage__product-icon-android'}, {headIcon: 'MainPage__product-icon-windows'}],
        pageNameDefault: ['Kaspersky Safe Kids', 'Kaspersky Password Manager', 'Kaspersky Internet Security for Android', 'Kaspersky Internet Security', 'Kaspersky Total Security']
    }
}
