import React, {useState} from "react"
import {textData} from "./textData"
import {apiUrl, req, apiLinkGuidCheckFr} from 'api-methods'
import {ajaxUpdate} from "helper-func"
import './App.css'

function App() {

    //1. Start Params
    let pageText        = textData
    let osData          = pageText.pageAll.osData
    let os              = pageText.pageAll.os
    let pageNameDefault = pageText.pageAll.pageNameDefault
    let pageLang        = 'de'
    let linkUrls        = ['info', 'download']
    let menuTab         = ['WINDOWS', 'MAC', 'IPHONE / IPAD', 'ANDROID']
    let routePage       = ['DEFAULT', 'KSK', 'KPM', 'KISA', 'KIS', 'KTS']
    let pageType        = routePage[0]
    let routePageFromProduct = routePage[0]
    let urlStatus       = (document.location.pathname).replace(/[^A-Za-z0-9-/]+/g, '').split('/')

    const [loader, setLoader]           = useState(true)
    const [reqOne, setReqOne]           = useState(false)
    const [notFound, setNotFound]       = useState(true)
    const [pageData, setPageData]       = useState({})
    const [helpMenuTab, setHelpMenuTab] = useState(0)

    //2.1. Get Data
    async function shortLinkData () {
        let params = {}
        if (urlStatus[1] + '' === linkUrls[0] && urlStatus[2] + '' !== '' && urlStatus[3] + '' !== '') {
            params['external_user_id'] = urlStatus[2]
            params['service_id'] = urlStatus[3]
        } else if (urlStatus[1] + '' === linkUrls[1] && urlStatus[2] + '' !== '') {
            params['key'] = urlStatus[2]
        }
        if (Object.keys(params).length > 0) {
            await req({method: apiUrl.fData, body: params}).then(data => {
                if (data.code === 0) {
                    apiLinkGuidCheckFr(data)
                    setPageData(data)
                    setNotFound(false)
                } else {
                    setNotFound(true)
                }
                setLoader(false)
            })
        }
    }

    //2.2. Get Data
    if (!reqOne) {if (!reqOne) {setReqOne(true);shortLinkData().then()}}

    //3. Os Match
    function osIsMatch (osName) {return os.indexOf(osName.split('LINK')[0])}

    //4. Page: title product in head
    function titleCard (htmlBlock) {
        let outHtml = ''
        htmlBlock.forEach(item => {if (item.type === 'head') {outHtml += '<div class="darkFont"><p>' + item.html + '</p></div>'} else if (item.type === 'brake') {outHtml += '<div class="breakText"></div>'} else if (item.type === 'link') {outHtml += '<a target="_blank" href="' + item.href + '"><p>' + item.title + '</p></a>'} else if (item.type === 'html') {outHtml += '<p>' + item.html + '</p>'}})
        return outHtml
    }

    //5.1. trim name os
    function textOsReplace (text, os) {if (os === 2) {text = text.split('PC').join('MAC');}return text}

    //5.2. trim name product
    function trimNameProduct (nameProduct) {return nameProduct.split('Kaspersky ').join('').split(' (')[0].split('1')[0].split('2')[0].split('3')[0].split('4')[0].split('5')[0]}

    //5. JSX Product Lick Card
    const ProductLink = (props) => {return <a target="_blank" href={props.link}>{props.osMatch < 3 && <div className="MainPage__productListDistr-linkBlock">{props.text}</div>}{props.osMatch === 3 && <div className="MainPage__product-icon-appstore"/>}{props.osMatch === 4 && <div className="MainPage__product-icon-googleplay"/>}{props.osMatch === 5 && <div className="microsoft"/>}</a>}

    //6. JSX Product Lick
    const ProductCard = (props) => {
        let osMatch = osIsMatch(props.obj)
        if (osMatch > 0) {
            return (<div>
                <div>
                    <div><div className={osData[osMatch].headIcon} /></div>
                    <div className="MainPage__productListDistr-desc">
                        <div className="MainPage__productListDistr-headBlock">{pageText[pageType][pageLang].productLinks.distrName[osMatch]}</div>
                        {(!!pageText[pageType][pageLang].productLinks.distrTitle && !!pageText[pageType][pageLang].productLinks.distrTitle[osMatch]) && <div className="MainPage__productListDistr-titleBlock"><i>{pageText[pageType][pageLang].productLinks.distrTitle[osMatch]}</i></div>}
                    </div>
                </div>
                <div className="MainPage__productListDistr-links"><ProductLink link={props.data} osMatch={osMatch} text={pageText[pageType][pageLang].productLinks.buttonText} /></div>
            </div>)
        }
        return null
    }

    //7. JSX Product-S!
    const ProductsCard = () => {
        return <div className="MainPage__productListDistr">
            {Object.keys(pageData.links).map((key, item) => {return <ProductCard obj={key} key={item} data={pageData.links[key]}  />})}
            {pageType === 'KTS' && Object.keys(pageText.pageAll.osStaticData).map((item, i) => {
                return <div key={i}>
                    <div><div><div className={pageText.pageAll.osStaticData[item].icon} /></div><div className="MainPage__productListDistr-desc"><div className="MainPage__productListDistr-headBlock">{pageText.pageAll.osStaticData[item].title}</div><div className="MainPage__productListDistr-titleBlock"><i>{pageText.pageAll.osStaticData[item].description}</i></div></div></div>
                    <div className="MainPage__productListDistr-links"><div className="MainPage__productListDistr-links"><a target="_blank" href={pageText.pageAll.osStaticData[item].link}><div className={pageText.pageAll.osStaticData[item].linkText.split('/').length > 1 ? '' : 'MainPage__productListDistr-linkBlock'} dangerouslySetInnerHTML={{ __html: pageText.pageAll.osStaticData[item].linkText }} /></a></div></div>
                </div>
            })}
        </div>
    }

    //8. Page: redraw internal route
    if (!!pageData.parentProductName && pageData.parentProductName + '' !== '') {pageNameDefault.forEach((item, index) => {if (pageData.parentProductName.indexOf(pageNameDefault[index]) > -1) {pageType = routePage[index + 1];routePageFromProduct = routePage[index + 1]}})}

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        <div className="App">
            <div className="MainPage__content">
                {loader ? <div className="preLoader"><div className="loader" /></div> : <div className="MainPage__product">
                    <div className="MainPage__width"><div className="MainPage__productHeader"><a target="_blank" href="https://www.kaspersky.de/"><div className="KasperLogo" /></a><div className="FreenetLogo" /></div></div>
                    <div className="hr" />
                    {notFound ? <div className="MainPage__width MainPage__notFound">404<br /><br />Oops! Page you are looking for doesn’t exist.</div> : (
                        <div className="MainPage__width">
                            {!!pageData.parentProductName && <div className="MainPage__productBody">
                                <div className="MainPage__productBody-image"><img className="MainPage__productBody-imageCover" src={pageData.cover} alt="Kaspersky" /></div>
                                <div className="MainPage__productBody-text"><div className="MainPage__productBody-mainHead">Kaspersky</div><div className="MainPage__productBody-mainTitle">{trimNameProduct(pageData.parentProductName)}</div></div>
                                <div className="MainPage__productBody-line" />
                                <div className="MainPage__productBody-description">
                                    <div className="MainPage__productBody-head">{pageText[pageType][pageLang].productTitle.title}</div>
                                    <div className="MainPage__productBody-title" dangerouslySetInnerHTML={{ __html: pageText[pageType][pageLang].productTitle.description }} />
                                    {(!!pageData.key && pageType === 'KSK') && <div className="MainPage__productBody-serial"><br /><br />{pageText[pageType][pageLang].productTitle.serial}{pageData.key}</div>}
                                </div>
                            </div>}
                            {(!!pageData.links && Object.keys(pageData.links).length > 0) && <div className="MainPage__productList">
                                {(['KISA']).indexOf(routePageFromProduct) > -1 && (<div><div className="MainPage__productListTitle MainPage__productListTitleFloat" dangerouslySetInnerHTML={{ __html: titleCard(pageText[pageType][pageLang].htmlBlock) }} /><ProductsCard /></div>)}
                                {(['KPM', 'KIS', 'KTS']).indexOf(routePageFromProduct) > -1 && (<div><ProductsCard /><div className="MainPage__productListTitle MainPage__productListTitleFloat" dangerouslySetInnerHTML={{ __html: titleCard(pageText[pageType][pageLang].htmlBlock) }} /></div>)}
                                {(['KSK']).indexOf(routePageFromProduct) > -1 && <div>
                                    <div className="MainPage__productList-helpList-align"><div className="MainPage__productList-helpList-title">{pageText[pageType][pageLang].productTableHelp.title}</div><div className="MainPage__productList-helpList-line" /></div>
                                    <div className="MainPage__productList-helpListTtable">
                                        <div className="MainPage__productList-helpListMenu">{menuTab.map((item, key) => {return <div key={key} onClick={e => setHelpMenuTab(key)} className={helpMenuTab === key ? 'MainPage__productList-helpListMenuActive' : 'MainPage__productList-helpListMenuPassive'}>{item}</div>})}</div>
                                        <div className="MainPage__productList-helpListPoint">
                                            {(['one', 'two', 'tree', 'four']).map((itemPoint, keyPoint) => {
                                                return <div className="MainPage__productList-helpListPointOne" key={keyPoint}>
                                                    <div className="MainPage__productList-helpLists">
                                                        <div className="MainPage__productList-helpListStep">{keyPoint + 1}</div>
                                                        <div className={!!pageText[pageType][pageLang].productTableHelp.tabs[helpMenuTab][itemPoint].icon ? 'MainPage__productList-helpListShortLine' : 'MainPage__productList-helpListLongLine'} dangerouslySetInnerHTML={{ __html: pageText[pageType][pageLang].productTableHelp.tabs[helpMenuTab][itemPoint].title }} />
                                                        {!!pageText[pageType][pageLang].productTableHelp.tabs[helpMenuTab][itemPoint].icon && <div className="MainPage__productList-helpListShortImage"><div className={pageText[pageType][pageLang].productTableHelp.tabs[helpMenuTab][itemPoint].icon} /></div>}
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="MainPage__productList-helpList-align"><div className="MainPage__productList-helpList-title">{pageText[pageType][pageLang].productLinks.title}</div><div className="MainPage__productList-helpList-line MainPage__productList-helpList-lineLong" /></div>
                                    <div className="MainPage__productList-helpList-links">{Object.keys(pageData.links).map((link, key) => {return <ProductLink key={key} link={pageData.links[link]} osMatch={osIsMatch(link)} text={textOsReplace(pageText[pageType][pageLang].productLinks.buttonText, osIsMatch(link))} />})}</div>
                                </div>}
                            </div>}
                        </div>
                    )}
                </div>}
            </div>
        </div>
    )
}

export default App
